import axios from "axios";
import Auth from "./auth";
import Secure from "./secure";
import config from "./config";

let loginServer = "";
let userServer = "";
let appServer = "";
let notificationServer = "";
let statisticalServer = "";
let geolocationServer = "";

if (config.environment === "dev") {
  loginServer = "https://devlogin.appsisto.com"; //"http://localhost:5001";"https://devlogin.appsisto.com";
  userServer = "https://devuser.appsisto.com"; //"http://localhost:5002"; //"https://devuser.appsisto.com";
  appServer = "https://devedit.appsisto.com"; //"http://localhost:5006"; //"https://devedit.appsisto.com";
  notificationServer = "https://devnotification.appsisto.com"; //"http://localhost:5004"; //"https://devnotification.appsisto.com";
  statisticalServer = "https://devstatistical.appsisto.com"; //"http://localhost:5007"; //"https://devstatistical.appsisto.com";
  geolocationServer = "https://devgeolocation.appsisto.com"; //"http://localhost:5008"; //"https://devgeolocation.appsisto.com";
} else if (config.environment === "test") {
  loginServer = "https://testlogin.appsisto.com"; //"http://localhost:5001"; //"https://loginserver.herokuapp.com";
  userServer = "https://testuser.appsisto.com"; //"http://localhost:5002"; //"https://userserver2.herokuapp.com";
  appServer = "https://testedit.appsisto.com"; //"http://localhost:5006"; //"https://testedit.appsisto.com";
  notificationServer = "https://testnotification.appsisto.com"; //"http://localhost:5004"; //"https://testnotification.appsisto.com";
  statisticalServer = "https://teststatistical.appsisto.com"; //"http://localhost:5007"; //"https://teststatistical.appsisto.com";
  geolocationServer = "https://testgeolocation.appsisto.com"; //"http://localhost:5008"; //"https://devgeolocation.appsisto.com";
} else if (config.environment === "production") {
  loginServer = "https://login.appsisto.com"; //"http://localhost:5001"; //"https://loginserver.herokuapp.com";
  userServer = "https://user.appsisto.com"; //"http://localhost:5002"; //"https://userserver2.herokuapp.com";
  appServer = "https://edit.appsisto.com"; //"http://localhost:5003"; //"https://appserver3.herokuapp.com";
  notificationServer = "https://notification.appsisto.com"; //"http://localhost:5004"; //"https://notificationserver.herokuapp.com";
  statisticalServer = "https://statistical.appsisto.com"; //"http://localhost:5007"; //"https://statistical.appsisto.com";
  geolocationServer = "https://geolocation.appsisto.com"; //"http://localhost:5008"; //"https://devgeolocation.appsisto.com";
}

export const signin = loginServer + "/login/signin2studio";
export const getAllSubjects = userServer + "/user/getAllSubjects";
export const getStudioUsers = userServer + "/user/getStudioUsers";
export const getAppUsers = userServer + "/user/getAppUsers";
export const signup2studio = userServer + "/user/signup2studio";
export const signup2app = userServer + "/user/signup2app";
export const createSubject = userServer + "/user/createSubject";
export const removeUserStudio = userServer + "/user/removeStudioUser";
export const removeUserApp = userServer + "/user/removeAppUser";
export const removeSubject = userServer + "/user/removeSubject";
export const updateSubject = userServer + "/user/updateSubject";
export const updateUserStudio = userServer + "/user/updateUserStudio";
export const updateUserStudioPassword =
  userServer + "/user/updateUserStudioPassword";
export const updateUserApp = userServer + "/user/updateUserApp";
export const updateUserAppPassword = userServer + "/user/updateUserAppPassword";
export const getStudioUsersCount = userServer + "/user/getStudioUsersCount";
export const getAppUsersCount = userServer + "/user/getAppUsersCount";
export const getSubjectsCount = userServer + "/user/getSubjectsCount";
export const getStudioSettings = userServer + "/user/getStudioSettings";
export const verifyAppUserFromStudio =
  userServer + "/user/verifyAppUserFromStudio";
export const getAppsCount = appServer + "/app/getAppsCount";
export const getApps = appServer + "/app/getApps";
export const getApp = appServer + "/app/getApp";
export const createApp = appServer + "/app/createApp";
export const updateApp = appServer + "/app/updateApp";
export const removeApp = appServer + "/app/removeApp";
export const createScreen = appServer + "/app/createScreen";
export const getScreens = appServer + "/app/getScreens";
export const updateScreenSettings = appServer + "/app/updateScreenSettings";
export const removeScreen = appServer + "/app/removeScreen";
export const getScreen = appServer + "/app/getScreen";
export const createScreenComponent = appServer + "/app/createScreenComponent";
export const removeScreenComponent = appServer + "/app/removeScreenComponent";
export const updateScreenComponent = appServer + "/app/updateScreenComponent";
export const setAppPermissions = appServer + "/app/setAppPermissions";
export const updateAppIcon = appServer + "/app/setAppIcon";
export const updateAppSplash = appServer + "/app/setAppSplash";
export const reorderScreenComponents =
  appServer + "/app/reorderScreenComponents";
export const reorderGroups = appServer + "/app/reorderGroups";
export const updateUserStudioLanguage =
  userServer + "/user/updateUserStudioLanguage";
export const setAppLanguages = appServer + "/app/setAppLanguages";
export const setAppLanguagesEnabled = appServer + "/app/setAppLanguagesEnabled";
export const updateScreenDrawer = appServer + "/app/updateScreenDrawer";
export const changeScreenDrawers = appServer + "/app/changeScreenDrawers";
export const updateScreenTabs = appServer + "/app/updateScreenTabs";
export const removeScreenTabs = appServer + "/app/removeScreenTabs";
export const getTabs = appServer + "/app/getTabs";
export const updateScreenOrder = appServer + "/app/updateScreenOrder";
export const updateBackground = appServer + "/app/updateBackground";
export const recalculateSystemMessages =
  appServer + "/app/recalculateSystemMessages";
export const updateComponentImage = appServer + "/app/updateComponentImage";
export const updateHeaderImage = appServer + "/app/updateHeaderImage";
export const updateScreenComponentType =
  appServer + "/app/updateScreenComponentType";
export const getAppLanguages = appServer + "/app/getAppLanguages";
export const updateCarouselItemImage =
  appServer + "/app/updateCarouselItemImage";
export const updateSliderItemImage = appServer + "/app/updateSliderItemImage";
export const updateSlider = appServer + "/app/updateSlider";
export const duplicateScreenComponent =
  appServer + "/app/duplicateScreenComponent";
export const createQuiz = appServer + "/app/createQuiz";
export const removeQuiz = appServer + "/app/removeQuiz";
export const getQuizzes = appServer + "/app/getQuizzes";
export const getQuiz = appServer + "/app/getQuiz";
export const updateQuizBaseSettings = appServer + "/app/updateQuizBaseSettings";
export const updateQuizQuestions = appServer + "/app/updateQuizQuestions";
export const saveQuizResultsSettings =
  appServer + "/app/saveQuizResultsSettings";
export const saveLoginSettings = appServer + "/app/saveLoginSettings";
export const saveCouponSettings = appServer + "/app/saveCouponSettings";
export const savePaidSettings = appServer + "/app/savePaidSettings";
export const createMindfulness = appServer + "/app/createMindfulness";
export const createMindfulnessPro = appServer + "/app/createMindfulnessPro";
export const createMindfulnessTouch = appServer + "/app/createMindfulnessTouch";
export const getMindfulness = appServer + "/app/getMindfulness";
export const getMindfulnessPro = appServer + "/app/getMindfulnessPro";
export const getMindfulnessTouch = appServer + "/app/getMindfulnessTouch";
export const getOneMindfulness = appServer + "/app/getOneMindfulness";
export const getOneMindfulnessPro = appServer + "/app/getOneMindfulnessPro";
export const getOneMindfulnessTouch = appServer + "/app/getOneMindfulnessTouch";
export const updateMindfulnessSettings =
  appServer + "/app/updateMindfulnessSettings";
export const updateMindfulnessProSettings =
  appServer + "/app/updateMindfulnessProSettings";
export const updateMindfulnessTouchSettings =
  appServer + "/app/updateMindfulnessTouchSettings";
export const removeMindfulness = appServer + "/app/removeMindfulness";
export const removeMindfulnessPro = appServer + "/app/removeMindfulnessPro";
export const removeMindfulnessTouch = appServer + "/app/removeMindfulnessTouch";
export const getAppQuizResults = appServer + "/app/getAppQuizResults";
export const getQuizResult = appServer + "/app/getQuizResult";
export const createMap = appServer + "/app/createMap";
export const removeMap = appServer + "/app/removeMap";
export const updateMapSettings = appServer + "/app/updateMapSettings";
export const updateMarkerImage = appServer + "/app/updateMarkerImage";
export const removeMarker = appServer + "/app/removeMarker";
export const updateMapData = appServer + "/app/updateMapData";
export const getMaps = appServer + "/app/getMaps";
export const getMap = appServer + "/app/getMap";
export const getMapData = appServer + "/app/getMapData";
export const notifyImmediatelyAll =
  notificationServer + "/notification/notifyImmediatelyAll";
export const getAppNotificationPushHistory =
  notificationServer + "/notification/getAppNotificationPushHistory";
export const getAppNotificationEmailHistory =
  notificationServer + "/notification/getAppNotificationEmailHistory";
export const getAppNotificationSmsHistory =
  notificationServer + "/notification/getAppNotificationSmsHistory";
export const getAppGeolocationNotificationPushHistory =
  notificationServer + "/notification/getAppGeolocationNotificationPushHistory";
export const resendNotification =
  notificationServer + "/notification/resendNotification";
export const getNotificationAppearance =
  appServer + "/app/getNotificationAppearance";

export const updateNotificationBaseSettings =
  appServer + "/app/updateNotificationBaseSettings";

export const createGroup = appServer + "/app/createGroup";
export const updateGroup = appServer + "/app/updateGroup";
export const removeGroup = appServer + "/app/removeGroup";
export const getAppForms = appServer + "/app/getAppForms";
export const getFormResults = appServer + "/app/getFormResults";
export const getFormResult = appServer + "/app/getFormResult";
export const updateFormEmails = appServer + "/app/setFormEmails";
export const updateQuizResults = appServer + "/app/updateQuizResults";
export const updateGalleryItemImage = appServer + "/app/updateGalleryItemImage";
export const updateGallery = appServer + "/app/updateGallery";
export const getAppsList = appServer + "/app/getAppsList";
export const getScreensList = appServer + "/app/getScreensList";
export const updateNotificationSegments =
  appServer + "/app/updateNotificationSegments";
export const deleteNotification =
  notificationServer + "/notification/deleteNotification";
export const deletePushNotification =
  notificationServer + "/notification/deletePushNotification";
export const deleteScheduledNotification =
  notificationServer + "/notification/deleteScheduledNotification";
export const restoreNotification =
  notificationServer + "/notification/restoreNotification";
export const getFirebaseProject =
  notificationServer + "/notification/getFirebaseProject";
export const setFirebaseProject =
  notificationServer + "/notification/setFirebaseProject";
export const deleteQuizResult = appServer + "/app/deleteQuizResult";
export const restoreQuizResult = appServer + "/app/restoreQuizResult";
export const deleteFormResult = appServer + "/app/deleteFormResult";
export const restoreFormResult = appServer + "/app/restoreFormResult";
export const updateWelcomeItemImage = appServer + "/app/updateWelcomeItemImage";
export const updateWelcome = appServer + "/app/updateWelcome";
export const duplicateQuiz = appServer + "/app/duplicateQuiz";
export const saveAdminSettings = appServer + "/app/saveAdminSettings";
export const duplicateGroup = appServer + "/app/duplicateGroup";
export const saveWebViewSettings = appServer + "/app/saveWebViewSettings";
export const updateGroupImage = appServer + "/app/updateGroupImage";
export const getChatRooms = appServer + "/app/getChatRooms";
export const getChatRoom = appServer + "/app/getChatRoom";
export const saveChatSettings = appServer + "/app/saveChatSettings";
export const adminReadChat = appServer + "/app/adminReadChat";
export const getMaps2 = appServer + "/app/getMaps2";
export const getMap2 = appServer + "/app/getMap2";
export const createMap2 = appServer + "/app/createMap2";
export const removeMap2 = appServer + "/app/removeMap2";
export const updateMap2Settings = appServer + "/app/updateMap2Settings";
export const getMarkerTypes = appServer + "/app/getMarkerTypes";
export const createMarkerType = appServer + "/app/createMarkerType";
export const removeMarkerType = appServer + "/app/removeMarkerType";
export const updateMarkerType = appServer + "/app/updateMarkerType";
export const updateMarkerTypeImage = appServer + "/app/updateMarkerTypeImage";
export const createMarkerState = appServer + "/app/createMarkerState";
export const removeMarkerState = appServer + "/app/removeMarkerState";
export const updateMarkerState = appServer + "/app/updateMarkerState";
export const getMarkerStates = appServer + "/app/getMarkerStates";
export const getMap22Studio = appServer + "/app/getMap22Studio";
export const changeMarkerState = appServer + "/app/changeMarkerState";
export const changeMarkerNote = appServer + "/app/changeMarkerNote";
export const updateMapData2 = appServer + "/app/updateMapData2";
export const getImportedMarkers = appServer + "/app/getImportedMarkers";
export const deleteChat = appServer + "/app/deleteChat";
export const getAppSubscribe = appServer + "/app/getAppSubscribe";
export const subscribePhone = appServer + "/app/subscribePhone";
export const subscribeEmail = appServer + "/app/subscribeEmail";
export const verifyPhone = appServer + "/app/verifyPhone";
export const verifyEmail = appServer + "/app/verifyEmail";
export const unsubscribePhone = appServer + "/app/unsubscribePhone";
export const deletePhone = appServer + "/app/deletePhone";
export const unsubscribeEmail = appServer + "/app/unsubscribeEmail";
export const getSmsSended = appServer + "/app/getSmsSended";
export const getEmailSended = appServer + "/app/getEmailSended";
export const createLeaflet = appServer + "/app/createLeaflet";
export const removeLeaflet = appServer + "/app/removeLeaflet";
export const getLeaflets = appServer + "/app/getLeaflets";
export const getLeaflet = appServer + "/app/getLeaflet";
export const createLeafletPage = appServer + "/app/createLeafletPage";
export const createLeafletItem = appServer + "/app/createLeafletItem";
export const saveLeafletPoints = appServer + "/app/saveLeafletPoints";
export const saveLeafletDetail = appServer + "/app/saveLeafletDetail";
export const reorderLeafletPages = appServer + "/app/reorderLeafletPages";
export const saveLeafletTitle = appServer + "/app/saveLeafletTitle";
export const saveLeafletText = appServer + "/app/saveLeafletText";
export const uploadLeafletDetailImage =
  appServer + "/app/uploadLeafletDetailImage";

export const saveLeafletLink = appServer + "/app/saveLeafletLink";
export const saveLeafletBackgroundColor =
  appServer + "/app/saveLeafletBackgroundColor";
export const saveLeafletTextColor = appServer + "/app/saveLeafletTextColor";
export const saveLeafletPointsColor = appServer + "/app/saveLeafletPointsColor";
export const deleteLeafletPage = appServer + "/app/deleteLeafletPage";
export const deleteLeafletItem = appServer + "/app/deleteLeafletItem";
export const getSmsCount = appServer + "/app/getSmsCount";
export const getAppDownload = statisticalServer + "/statistical/getAppDownload";
export const getAppUsage = statisticalServer + "/statistical/getAppUsage";
export const getScreensUsage =
  statisticalServer + "/statistical/getScreensUsage";
export const getComponentsUsage =
  statisticalServer + "/statistical/getComponentsUsage";

export const getSignUpUsage = statisticalServer + "/statistical/getSignUpUsage";

export const getAppUsersSignedUpCount =
  statisticalServer + "/statistical/getAppUsersSignedUpCount";
export const getNotificationsUsage =
  statisticalServer + "/statistical/getNotificationsUsage";

export const saveOta = appServer + "/app/saveOta";
export const updateNotificationTimeThreshold =
  appServer + "/app/updateNotificationTimeThreshold";

export const updateNotificationEnableEmailSms =
  appServer + "/app/updateNotificationEnableEmailSms";

export const getAppNotificationPushScheduled =
  notificationServer + "/notification/getAppNotificationPushScheduled";
export const getAppNotificationEmailScheduled =
  notificationServer + "/notification/getAppNotificationEmailScheduled";
export const getAppNotificationSmsScheduled =
  notificationServer + "/notification/getAppNotificationSmsScheduled";

export const getStudioAppSettings = appServer + "/app/getStudioAppSettings";
export const updateStudioAppSettings =
  appServer + "/app/updateStudioAppSettings";
export const getCare = appServer + "/app/getCare";
export const saveCareMessage = appServer + "/app/saveCareMessage";
export const deleteCareMessage = appServer + "/app/deleteCareMessage";
export const saveCareStatus = appServer + "/app/saveCareStatus";
export const uploadCareImage = appServer + "/app/uploadCareImage";
export const deleteCareImage = appServer + "/app/deleteCareImage";
export const uploadCareDocument = appServer + "/app/uploadCareDocument";
export const deleteCareDocument = appServer + "/app/deleteCareDocument";
export const uploadCareVideo = appServer + "/app/uploadCareVideo";
export const deleteCareVideo = appServer + "/app/deleteCareVideo";
export const sendCareEmail = appServer + "/app/sendCareEmail";
export const createGeolocationZone = appServer + "/app/createGeolocationZone";
export const getGeolocationZones = appServer + "/app/getGeolocationZones";
export const getGeolocationZone = appServer + "/app/getGeolocationZone";
export const updateGeolocationZone = appServer + "/app/updateGeolocationZone";
export const getLeafletReader = appServer + "/app/getLeafletReader";
export const getUserNotifications = appServer + "/app/getUserNotifications";
export const saveUserNotificationsSettings =
  appServer + "/app/saveUserNotificationsSettings";
export const saveSearchSettings = appServer + "/app/saveSearchSettings";
export const saveUserNotificationsBirthday =
  appServer + "/app/saveUserNotificationsBirthday";
export const sendCustomerEmail = appServer + "/app/sendCustomerEmail";
export const saveLikedMarkerSettings =
  appServer + "/app/saveLikedMarkerSettings";
export const getPlacesCoupons = appServer + "/app/getPlacesCoupons";
export const createCoupon = appServer + "/app/createCoupon";
export const getPlaceCoupons = appServer + "/app/getPlaceCoupons";
export const deleteCoupon = appServer + "/app/deleteCoupon";
export const updateMap2ImportSegments =
  appServer + "/app/updateMap2ImportSegments";
export const getStudioUserAccess = userServer + "/user/getStudioUserAccess";
export const updateStudioUserScreensAccess =
  userServer + "/user/updateStudioUserScreensAccess";
export const updateStudioUserSectionsAccess =
  userServer + "/user/updateStudioUserSectionsAccess";
export const getParos = appServer + "/app/getParos";
export const createParo = appServer + "/app/createParo";
export const deleteParo = appServer + "/app/deleteParo";
export const getParo = appServer + "/app/getParo";
export const updateParo = appServer + "/app/updateParo";
export const updateParoImage = appServer + "/app/updateParoImage";
export const updateParoAppearance = appServer + "/app/updateParoAppearance";
export const createParoScreen = appServer + "/app/createParoScreen";
export const deleteParoScreen = appServer + "/app/deleteParoScreen";
export const getParoScreens = appServer + "/app/getParoScreens";
export const reorderParoScreens = appServer + "/app/reorderParoScreens";
export const getParoScreen = appServer + "/app/getParoScreen";
export const updateParoScreenBasic = appServer + "/app/updateParoScreenBasic";
export const updateParoScreenImage = appServer + "/app/updateParoScreenImage";
export const updateParoScreenAppearance =
  appServer + "/app/updateParoScreenAppearance";
export const createParoQuestion = appServer + "/app/createParoQuestion";
export const deleteParoQuestion = appServer + "/app/deleteParoQuestion";
export const getParoQuestion = appServer + "/app/getParoQuestion";
export const updateParoQuestion = appServer + "/app/updateParoQuestion";
export const reorderParoAnswers = appServer + "/app/reorderParoAnswers";
export const createParoAnswer = appServer + "/app/createParoAnswer";
export const deleteParoAnswer = appServer + "/app/deleteParoAnswer";
export const createParoForm = appServer + "/app/createParoForm";
export const deleteParoForm = appServer + "/app/deleteParoForm";
export const getParoForm = appServer + "/app/getParoForm";
export const createParoFormComponent =
  appServer + "/app/createParoFormComponent";
export const deleteParoFormComponent =
  appServer + "/app/deleteParoFormComponent";
export const updateParoForm = appServer + "/app/updateParoForm";
export const reorderParoFormComponents =
  appServer + "/app/reorderParoFormComponents";
export const reorderScreens = appServer + "/app/reorderScreens";
export const getParoExternal = appServer + "/app/getParoExternal";
export const sendLastMessageEmail = appServer + "/app/sendLastMessageEmail";
export const updateLeafletImage = appServer + "/app/updateLeafletImage";
export const getParoResultsForms = appServer + "/app/getParoResultsForms";
export const saveParoResults = appServer + "/app/saveParoResults";
export const verifyParoResult = appServer + "/app/verifyParoResult";
export const getParoResults = appServer + "/app/getParoResults";
export const getParoResultsQuestions =
  appServer + "/app/getParoResultsQuestions";
export const updateCouponPrefix = appServer + "/app/updateCouponPrefix";
export const updateCoupon = appServer + "/app/updateCoupon";
export const getNotificationsRegisteredUsers =
  appServer + "/app/getNotificationsRegisteredUsers";
export const updateRegisteredUsers = appServer + "/app/updateRegisteredUsers";
export const getAppState = appServer + "/app/getAppState";
export const updateAppState = appServer + "/app/updateAppState";
export const createShortcut = appServer + "/app/createShortcut";
export const getShortcuts = appServer + "/app/getShortcuts";
export const removeShortcut = appServer + "/app/removeShortcut";
export const getScreensByIds = appServer + "/app/getScreensByIds";
export const getMapsByIds = appServer + "/app/getMapsByIds";
export const getLeafletsByIds = appServer + "/app/getLeafletsByIds";
export const saveStudioNews = appServer + "/app/saveStudioNews";
export const deleteStudioNews = appServer + "/app/deleteStudioNews";
export const getStudioNews = appServer + "/app/getStudioNews";
export const reorderMapTypes = appServer + "/app/reorderMapTypes";
export const reorderMapStates = appServer + "/app/reorderMapStates";
export const createAccomodation = appServer + "/app/createAccomodation";
export const importOwners = appServer + "/app/importOwners";
export const getOwners = appServer + "/app/getOwners";
export const getAccomodationPrice = appServer + "/app/getAccomodationPrice";
export const updateAccomodationPrice =
  appServer + "/app/updateAccomodationPrice";
export const createProperty = appServer + "/app/createProperty";
export const getProperty = appServer + "/app/getProperty";
export const createEvent = appServer + "/app/createEvent";
export const getEvents = appServer + "/app/getEvents";
export const deleteEvent = appServer + "/app/deleteEvent";
export const updateStudioSettings = userServer + "/user/updateStudioSettings";
export const getCareMedia = appServer + "/app/getCareMedia";
export const createCareMedia = appServer + "/app/createCareMedia";
export const removeCareMedia = appServer + "/app/removeCareMedia";
export const updateCareMedia = appServer + "/app/updateCareMedia";
export const updateCareMediaLink = appServer + "/app/updateCareMediaLink";
export const reorderCareMedia = appServer + "/app/reorderCareMedia";
export const getCareDocuments = appServer + "/app/getCareDocuments";
export const createCareDocument = appServer + "/app/createCareDocument";
export const removeCareDocument = appServer + "/app/removeCareDocument";
export const updateCareDocument = appServer + "/app/updateCareDocument";
export const updateCareDocumentLink = appServer + "/app/updateCareDocumentLink";
export const reorderCareDocuments = appServer + "/app/reorderCareDocuments";
export const getPayments = appServer + "/app/getPayments";
export const updateStudioUserAppsAccess =
  userServer + "/user/updateStudioUserAppsAccess";
export const createMarker = appServer + "/app/createMarker";
export const createTemplate = appServer + "/app/createTemplate";
export const getTemplates = appServer + "/app/getTemplates";
export const removeTemplate = appServer + "/app/removeTemplate";
export const getEshop = appServer + "/app/getEshop";
export const updateEshop = appServer + "/app/updateEshop";
export const updateTemplate = appServer + "/app/updateTemplate";
export const getCouponPrefix = appServer + "/app/getCouponPrefix";
export const getCouponAppearance = appServer + "/app/getCouponAppearance";
export const getCustomerEmails = appServer + "/app/getCustomerEmails";
