import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { List, Typography } from "@mui/material";

// project imports
import NavItem from "../NavItem";
import NavCollapse from "../NavCollapse";
import Auth from "../../../../../utils/auth";
import secure from "../../../../../utils/secure";
import { useLocation } from "react-router";
import axios from "axios";
import { getStudioUserAccess } from "../../../../../utils/rest";
import { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { CURRENT_PAGE } from "./../../../../../store/actions";

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item, appExtras }) => {
  const theme = useTheme();
  const location = useLocation();
  const user = useRef(Auth.getUser());
  const dispatch = useDispatch();
  //const idUser = user.current._id;
  const alert = useAlert();
  const [sectionsAccess, setSectionsAccess] = useState([]);

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const currentPath = pathSegments[pathSegments.length - 1] || "app-sections";

    dispatch({ type: CURRENT_PAGE, payload: currentPath });
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (location.pathname !== "/") {
      onGetStudioUserSectionsAccess();
    } else setSectionsAccess(["dashboard", "subjects"]);
  }, []);

  async function onGetStudioUserSectionsAccess() {
    let token = await Auth.getToken();
    try {
      let encrypted = secure.encrypt({
        idApp: location.state.app._id,
        idUser: user.current._id,
        token,
      });

      let res = await axios.get(getStudioUserAccess, {
        params: { appsisto: encrypted },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Access-Control-Allow-Credentials": true,
        },
      });
      setSectionsAccess(res.data.access.sectionsAccess);
    } catch (err) {
      alert.show("Něco se porouchalo...", {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      console.log(err);
      return [];
    }
  }
  const filteredItems = item.children?.filter((menu) => {
    if (!appExtras && menu.id === "app-extras") {
      return false;
    }
    return sectionsAccess.includes("*") || sectionsAccess.includes(menu.id);
  });

  // menu list collapse & items
  const items = filteredItems.map((menu) => {
    switch (menu.type) {
      case "collapse":
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case "item":
        return (
          <NavItem key={menu.id} item={menu} level={1} onClick={menu.onClick} />
        );
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography
              variant="caption"
              sx={{ ...theme.typography.menuCaption }}
              display="block"
              gutterBottom
            >
              {item.title}
              {item.caption && (
                <Typography
                  variant="caption"
                  sx={{ ...theme.typography.subMenuCaption }}
                  display="block"
                  gutterBottom
                >
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
